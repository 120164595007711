.react-select .select {
  font-weight: 700;
  color: black;
  width: 100% !important;
  text-align: center;
}

.react-select .css-b62m3t-container {
  width: 100% !important;
}

.react-select .css-tj5bde-Svg {
  color: black;
}

.react-select .css-2613qy-menu {
  z-index: 9999 !important;
  width: 100% !important;
}
