@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=DynaPuff:wght@400..700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
}

.daisy-progress::-webkit-progress-value {
  background-color: #ff8c33 !important;
}

.daisy-progress {
  background-color: #eee !important;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

button {
  cursor: pointer;
}

.longModal {
  padding: 0 !important;
  min-height: 75vh;
}

.joinquizmasterModal,
.quizReferenceModal,
.likesModal,
.mediaModal,
.practiceFilterModal {
  padding: 0 !important;
}

.custom-modal {
  padding: 0 !important;
}

.quizReferenceModal {
  padding-bottom: 0.5rem !important;
}

.likesModal,
.mediaModal {
  width: 100% !important;
  width: 90vw !important;
  max-width: 500px;
  margin: 0 !important;
}

.likesModal {
  max-height: 80vh;
  border-radius: 10px;
}

.likesModal .react-responsive-modal__modal {
  height: auto;
}

.likesModal .react-responsive-modal-closeButton,
.mediaModal .react-responsive-modal-closeButton {
  margin-top: -7px !important;
  margin-right: -6px !important;
}

.joinquizmasterModal .react-responsive-modal-closeButton {
  margin-top: -6px !important;
  margin-right: -7px !important;
}

.languageSelectModal .react-responsive-modal-closeButton {
  margin-top: -2px !important;
  margin-right: -6px !important;
}

.practiceFilterModal .react-responsive-modal-closeButton {
  margin-top: -10px !important;
  margin-right: -6px !important;
}

.likesModalContent {
  max-height: calc(80vh - 80px);
  overflow-y: auto;
}

.questionSelectionModal {
  width: 96vw !important;
  padding: 0 !important;
  max-width: 600px !important;
  margin: 0.6rem auto;
}

.academicSubcategoriesModal {
  width: 85vw !important;
  max-width: 500px !important;
  margin: 0.6rem auto;
}
