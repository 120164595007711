@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Nanum+Brush+Script&display=swap");

:root {
  --bubble: 4.8rem;
  --bubble-radius: 999px;
  --shadow: calc(var(--bubble) / 10);
  --blue: 8, 143, 215;
  --pink: 245, 20, 155;
  --light: 240, 248, 255;
}

.bubbles-container {
  position: absolute;
  right: 2%;
  top: 80%;
  transform: translateY(-50%);
  pointer-events: none;
}

.bubble {
  border-radius: var(--bubble-radius);
  height: var(--bubble);
  width: var(--bubble);
  margin: 1rem 0;
  position: absolute;
  right: 2%;
  top: 80%;
  background: linear-gradient(
      -175deg,
      transparent 70%,
      rgba(var(--blue), 0.2),
      transparent 90%
    ),
    radial-gradient(transparent 0% 50%, rgba(var(--light), 0.5) 70% 100%);
  box-shadow: inset 0 0 var(--shadow) rgb(var(--blue)),
    0 0 1px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  text-align: center;
  animation: ascend 6s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}

.bubble:nth-child(1) {
  --bubble: 3.2rem;
  font-size: 0.95rem;
  background: #f1602c;
  box-shadow: inset 0 0 var(--shadow) #e05320, 0 0 1px rgba(0, 0, 0, 0.2);
  animation-delay: 0s;
}

.bubble:nth-child(2) {
  --bubble: 4rem;
  font-size: 1rem;
  animation-delay: 2s;
  opacity: 0;
}

.bubble:nth-child(3) {
  --bubble: 4rem;
  animation-delay: 4s;
  opacity: 0;
  background: #17a90a;
  box-shadow: inset 0 0 var(--shadow) #128b07, 0 0 1px rgba(0, 0, 0, 0.2);
}

@keyframes ascend {
  0% {
    transform: translate(20vw, 5vw) scale(1);
    opacity: 0;
    z-index: 3;
  }
  10% {
    opacity: 1;
  }
  50% {
    transform: translate(-15vw, -3vw) scale(1);
    opacity: 0.8;
  }
  100% {
    transform: translate(20vw, -10vw) scale(1);
    opacity: 0;
    z-index: 1;
  }
}

.bubble span {
  font-family: "Kalam", cursive;
}
